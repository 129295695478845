<template>
    <SmartTable url="/settings/event-training-category-type/index" base_path="/settings/event-training-category-type"></SmartTable>
</template>
<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartTable
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Categorie Evento CONI", route: "/settings/event-training-category-type/index" },
             { title: "Ricerca Categorie Evento CONI" }
         ]);
    },
 };
</script>
